import { forwardRef, useRef } from "react";
import {
    FaHtml5,
    FaCss3,
    FaBootstrap,
    FaPython,
    FaPhp,
    FaVuejs,
    FaAngular,
    FaDatabase,
} from "react-icons/fa";
import { BiLogoJavascript, BiLogoTypescript } from "react-icons/bi";
import { RiReactjsFill, RiTailwindCssFill } from "react-icons/ri";
import { SiLua, SiNestjs, SiDjango } from "react-icons/si";
import { AiOutlineConsoleSql } from "react-icons/ai";

const Frameworks = () => {
    return (
        <div className="w-full">
            <div className="md:max-w-[1480px] max-w-[550px] m-auto px-5 pt-20" data-aos="zoom-in">
                <h1 className="text-2xl font-semibold text-center text-gray-100 capitalize lg:text-3xl">
                    Główny stack technologiczny
                </h1>

                <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-4 xl:grid-cols-7">
                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <FaHtml5 size={25} className="text-white mx-1" />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            HTML
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <FaCss3 size={25} className="text-white mx-1" />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            CSS
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <BiLogoJavascript
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            JavaScript
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <BiLogoTypescript
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            TypeScript
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <RiReactjsFill
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            ReactJS
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <FaBootstrap
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            Bootstrap
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <RiTailwindCssFill
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            Tailwind CSS
                        </h1>
                    </div>
                </div>
            </div>

            <div className="md:max-w-[1480px] max-w-[550px] m-auto px-5 pt-20" data-aos="zoom-in">
                <h1 className="text-2xl font-semibold text-center text-gray-100 capitalize lg:text-3xl">
                    Technologie, z którymi miałem styczność
                </h1>

                <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-4 xl:grid-cols-8">
                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <FaAngular size={25} className="text-white mx-1" />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            ANGULAR
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <FaVuejs size={25} className="text-white mx-1" />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            VUE.JS
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <FaPython
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            Python
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <SiDjango
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            Django
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <SiNestjs size={25} className="text-white mx-1" />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            NestJS
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <FaPhp
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            PHP
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <SiLua
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            LUA
                        </h1>
                    </div>

                    <div className="flex flex-col items-center p-3text-center">
                        <span className="inline-block p-3">
                            <FaDatabase
                                size={25}
                                className="text-white mx-1"
                            />
                        </span>

                        <h1 className="text-md font-semibold text-gray-400 capitalize">
                            Bazy Danych
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Frameworks;
