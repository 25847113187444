import { useState } from "react";
import Logo from "../assets/logo.webp";
import { CiMenuFries } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
    const [toggle, setToggle] = useState(false);
    const handleClick = () => setToggle(!toggle);

    const handleGithubClick = () => {
        window.open("https://github.com/korpus99", "_blank");
    };

    return (
        <div className="w-full h-[80px] border-b border-gray-700 z-10 bg-gradient-1">
            <div className="md:max-w-[1480px] max-w-[550px] px-5 m-auto w-full h-full flex justify-between items-center">
                <img src={Logo} alt="Logo MK" className="h-[45px]" />

                <div className="hidden md:flex items-center">
                    <ul className="flex gap-4">
                        <Link to="/">
                            <li className="p-2 rounded cursor-pointer font-bold hover:bg-gray-700 duration-300">
                                O mnie
                            </li>
                        </Link>
                        <Link to="/frameworks">
                            <li className="p-2 rounded cursor-pointer font-bold hover:bg-gray-700 duration-300">
                                Technologie
                            </li>
                        </Link>
                        <Link to="/projects">
                            <li className="p-2 rounded cursor-pointer font-bold hover:bg-gray-700 duration-300">
                                Moje prace
                            </li>
                        </Link>
                    </ul>
                </div>

                <div className="hidden md:flex">
                    <button
                        className="px-2 py-1 rounded-md"
                        onClick={handleGithubClick}
                    >
                        <FaGithub
                            size="25"
                            className="text-gray-400 hover:text-gray-300 duration-300"
                        />
                    </button>
                </div>

                <div className="md:hidden text-gray-400 hover:text-gray-300 duration-300 cursor-pointer" onClick={handleClick}>
                    {toggle ? (
                        <IoMdClose size={20} />
                    ) : (
                        <CiMenuFries size={20} />
                    )}
                </div>
            </div>

            <div
                className={`absolute z-10 p-4 bg-gradient-1 w-full px-8 md:hidden ${
                    toggle ? "show-menu" : "hide-menu"
                }`}
            >
                <ul>
                    <Link to="/">
                        <li className="my-1 p-2 rounded cursor-pointer font-bold hover:bg-gray-700 duration-300">
                            O mnie
                        </li>
                    </Link>
                    <Link to="/frameworks">
                        <li className="my-1 p-2 rounded cursor-pointer font-bold hover:bg-gray-700 duration-300">
                            Technologie
                        </li>
                    </Link>
                    <Link to="/projects">
                        <li className="my-1 p-2 rounded cursor-pointer font-bold hover:bg-gray-700 duration-300">
                            Moje prace
                        </li>
                    </Link>
                    <div className="flex my-1">
                        <button
                            className="px-2 py-1 rounded-md"
                            onClick={handleGithubClick}
                        >
                            <div className="flex font-bold">
                                <FaGithub
                                    size="25"
                                    className="text-gray-400 hover:text-gray-300 duration-300 mr-2"
                                />
                                Github
                            </div>
                        </button>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
