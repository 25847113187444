import Films from "../assets/projects/films.webp";
import Empty from "../assets/example.webp";

const Projects = () => {
    return (
        <div className="w-full">
            <div className="mt-5 md:max-w-[1480px] max-w-[550px] m-auto px-5 py-5">
                <div className="items-center lg:flex mt-5" data-aos="zoom-in">
                    <div className="w-full lg:w-1/2">
                        <div className="lg:max-w-lg">
                            <h1 className="text-3xl font-semibold lg:text-4xl">
                                Biblioteka Filmowa
                            </h1>

                            <p className="mt-3 text-gray-400">
                                Projekt powstał w oparciu o gotowe API TVMAZE.
                                <br /> Do stworzenia projektu został
                                wykorzystany ReactJS, Tailwind CSS i TypeScript.
                            </p>

                            <div className="w-full mt-6">
                                <button
                                    onClick={() =>
                                        window.open(
                                            "https://filmlibrary.mat-kor.pl",
                                            "_blank"
                                        )
                                    }
                                    className="w-full mr-5 px-5 py-2 text-sm mb-2 tracking-wider text-white uppercase transition-colors duration-300 transform bg-blue-600 rounded-lg lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
                                >
                                    Podgląd DEMO
                                </button>
                                <button
                                    onClick={() =>
                                        window.open(
                                            "https://github.com/korpus99/REACT_FilmLibrary",
                                            "_blank"
                                        )
                                    }
                                    className="w-full px-5 py-2 text-sm tracking-wider text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded-lg lg:w-auto hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                >
                                    Zobacz kod
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
                        <img
                            className="w-full h-full lg:max-w-3xl"
                            src={Films}
                            alt="Biblioteka Filmowa"
                        />
                    </div>
                </div>

                <div className="items-center lg:flex mt-5" data-aos="zoom-in">
                    <div className="w-full lg:w-1/2 text-left lg:text-right lg:order-1">
                        <div className="">
                            <h1 className="text-3xl font-semibold lg:text-4xl">
                                Zarządzanie książkami i czytelnikami
                            </h1>

                            <p className="mt-3 text-gray-400">
                                W trakcie realizacji...
                            </p>

                            <div className="w-full mt-6">
                                <button
                                    disabled
                                    className="w-full mr-5 px-5 py-2 text-sm mb-2 tracking-wider text-white uppercase transition-colors duration-300 transform bg-blue-600 rounded-lg lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
                                >
                                    Podgląd DEMO
                                </button>
                                <button
                                    disabled
                                    className="w-full px-5 py-2 text-sm tracking-wider text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded-lg lg:w-auto hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                >
                                    Zobacz kod
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2 ">
                        <img
                            className="w-full h-full lg:max-w-3xl"
                            src={Empty}
                            alt="Zarządzanie książkami i czytelnikami"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
