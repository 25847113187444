import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProjectsPage from "./pages/ProjectsPage";
import FrameworksPage from "./pages/FrameworksPage";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
    AOS.init();

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path="/frameworks" element={<FrameworksPage />} />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route path="*" element={<HomePage />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
