import Frameworks from "../components/Frameworks";
import Navbar from "../components/Navbar";

const FrameworksPage = () => {

    return (
        <div className="w-full">
            <div className="bg-gradient-1 text-white min-h-screen">
                <Navbar />
                <Frameworks />
            </div>
        </div>
    );
};

export default FrameworksPage;
