import Navbar from "../components/Navbar";
import Projects from "../components/Projects";

const ProjectsPage = () => {

    return (
        <div className="w-full">
            <div className="bg-gradient-1 text-white min-h-screen">
                <Navbar />
                <Projects />
            </div>
        </div>
    );
};

export default ProjectsPage;
