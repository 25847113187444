import AboutImage from "../assets/11.webp";
import { FaGithub, FaDiscord } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

const About = () => {
    const handleGithubClick = () => {
        window.open("https://github.com/korpus99", "_blank");
    };

    return (
        <div className="w-full">
            <div className="md:max-w-[1480px] max-w-[550px] m-auto grid md:grid-cols-2 px-5 pt-5">
                <div data-aos="zoom-in">
                    <h1 className="mt-20 text-[1.8rem] font-light leading-tight sm:mt-0 sm:text-left lg:text-[3rem] md:text-[2.1rem]">
                        <span className="">Siema.</span>
                        <br />
                        <span className="font-bold"> Mam na imię Mateusz.</span>
                        <br />
                        <span className="">Jestem frontend developerem.</span>
                    </h1>
                    <h2 className="mt-10 leading-loose sm:text-left text-gray-400">
                        Programista - samouk. Specjalizuję się w tworzeniu
                        interaktywnych i estetycznych stron oraz aplikacji,
                        które łączą funkcjonalność z nowoczesnym designem.
                        Czerpię dużą przyjemność i satysfakcję z poszerzania
                        mojej wiedzy i pracy nad projektami.
                        <br />
                        <br />
                        Prywatnie jestem miłośnikiem wszelakich sportów, gier
                        komputerowych oraz pisania do nich modyfikacji.
                    </h2>
                </div>
                <div className="flex md:justify-end justify-center" data-aos="zoom-in">
                    <img src={AboutImage} className="max-h-[700px]" />
                </div>
                <div className="flex items-center lg:flex-row flex-col pt-12" data-aos="zoom-in">
                    <div className="flex justify-center items-center gap-2 px-5 py-1">
                        <button
                            className="rounded-md"
                            onClick={handleGithubClick}
                        >
                            <FaGithub
                                size="25"
                                className="text-gray-400 hover:text-gray-300 duration-300"
                            />
                        </button>
                        korpus99
                    </div>
                    <div className="flex justify-center items-center gap-2 px-5">
                        <FaDiscord
                            size="25"
                            className="text-gray-400 hover:text-gray-300 duration-300"
                        />
                        korpus99
                    </div>
                    <div className="flex justify-center items-center gap-2 px-5">
                        <SiGmail
                            size="25"
                            className="text-gray-400 hover:text-gray-300 duration-300"
                        />
                        mateusz.korpusik99gmail.com
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
