import About from "../components/About";
import Navbar from "../components/Navbar";

const HomePage = () => {

    return (
        <div className="w-full">
            <div className="bg-gradient-1 text-white min-h-screen">
                <Navbar />
                <About />
            </div>
        </div>
    );
};

export default HomePage;
